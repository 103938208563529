<template>
  <div>

    <h5 class="mt-4 mb-8">{{ $t('MEMBER_PROPERTY.PROPERTY_GROUP_HEADER') }}</h5>

    <b-form class="mt-8">
      <b-form-group id="input-group-group_id" label="ID" label-for="input-group_id">
        <b-form-input
          id="input-group_id"
          v-model="form.group_id"
          type="text"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-name" :label="$t('COMMON.NAME')" label-for="input-name">
        <b-form-input id="input-name" v-model="form.name" type="text"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-descr" :label="$t('COMMON.DESCR')" label-for="input-descr">
        <b-form-textarea
          id="input-descr"
          size="sm"
          :placeholder="$t('COMMON.DESCR')"
          v-model="form.descr"
        ></b-form-textarea>
      </b-form-group>

      <h5 class="mb-8 mt-8">{{$t('MEMBER_PROPERTY.PRIORITY')}}</h5>
      <b-form-group
        id="mult-group"
        :label="$t('MEMBER_PROPERTY.PRIORITY_INFO')"
        label-for="mult"
      >
        <b-form-select id="mult" v-model="form.priority" :options="priority_options"></b-form-select>
      </b-form-group>


      <h5 class="mb-8 mt-8">{{$t('MEMBER_PROPERTY.IS_MULTI')}}</h5>
      <b-form-group
        id="mult-group"
        :label="$t('PAGES.ATTRIBUTES.MULTIPLE_OPTIONS_INFO')"
        label-for="mult"
      >
        <b-form-select id="mult" v-model="form.is_multi" :options="is_multi_options"></b-form-select>
      </b-form-group>

      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input
              type="checkbox"
              name=""
              :disabled="form.is_multi"
              v-model="form.is_dropdown"
              @click="form.is_dropdown = !form.is_dropdown"
            />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{$t('PAGES.ATTRIBUTES.IS_DROPDOWN')}}</span>
        </div>
      </b-form-group>

      <h5 class="mb-8 mt-8">{{$t('PAGES.ATTRIBUTES.OTHER_OPTIONS')}}</h5>
      <b-form-group>
        <div class="d-flex align-items-center mb-4">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input
              type="checkbox"
              name=""
              v-model="form.is_registration"
              @click="form.is_registration = !form.is_registration"
            />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{$t('PAGES.ATTRIBUTES.SHOW_AT_REGISTRATION')}}</span>
        </div>
        <div class="d-flex align-items-center mb-4">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input
              type="checkbox"
              name=""
              v-model="form.is_profile"
              @click="form.is_profile = !form.is_profile"
            />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{$t('PAGES.ATTRIBUTES.SHOW_AT_PROFILE')}}</span>
        </div>
        <div class="d-flex align-items-center">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input
              type="checkbox"
              name=""
              v-model="form.is_mandatory"
              @click="form.is_mandatory = !form.is_mandatory"
            />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{$t('PAGES.ATTRIBUTES.MANDATORY')}}</span>
        </div>
      </b-form-group>

      <MemberPropertyTable
        v-if="form.group_id"
        :group_id="form.group_id"
      />

      <b-row>
        <b-col class="ml-8">
          <ul>
            <li
              v-for="(error, idx) in errors"
              :key="idx"
            >
              {{ error.text }}
            </li>

          </ul>
        </b-col>

      </b-row>

      <RightSaveButton
        ref="save-button"
        :text="$t('MEMBER_PROPERTY.SAVE_PROPERTY_GROUP')"
        @clicked="save_data()"
      />

    </b-form>
  </div>

</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";


ul li {
  list-style: none;
  color: red;
  font-weight: bold;
}

</style>

<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import TipLabel from '@/view/components/TipLabel.vue';

import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import MemberPropertyTable from '@/view/pages/ml/member_property/MemberPropertyTable.vue'

export default {

  name: 'property_group_editor',

  components: {
    Confirm,
    TipLabel,
    RightSaveButton,
    MemberPropertyTable
  },

  mixins: [ toasts ],

  emits: ['created', 'updated'],

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods']),

  },
  mounted() {
    const loader = this.$loading.show();

    if (this.item) {
      this.form = { ...this.item };

      this.form.is_profile = this.form.is_profile !== null && this.form.is_profile !== undefined ? this.form.is_profile : false;
    }
    else {
      this.reset_form();
    }

    loader && loader.hide();
  },
  data() {
    return {
      priority_options: [
        { text: '0', value: 0 },
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
        { text: '6', value: 6 },
        { text: '7', value: 7 },
        { text: '8', value: 8 },
        { text: '9', value: 9 },
        { text: '10', value: 10 },
        { text: '11', value: 11 },
        { text: '12', value: 12 },
        { text: '13', value: 13 },
        { text: '14', value: 14 },
        { text: '15', value: 15 },
        { text: '16', value: 16 },
        { text: '17', value: 17 },
        { text: '18', value: 18 },
        { text: '19', value: 19 },
        { text: '20', value: 20 }
      ],
      is_multi_options: [
        { text: this.$t('PAGES.ATTRIBUTES.SINGLE_SELECTION'), value: false },
        { text: this.$t('PAGES.ATTRIBUTES.MULTI_SELECTION'), value: true }
      ],

      errors: [],
      form: {
        period_id: null
      }
    }
  },
  props: ['item'],
  watch: {

    'form.is_multi'() {
      if (this.form.is_multi) {
        this.form.is_dropdown = false;
      }
    },

    item: {
      deep: true,
      handler(val) {

        if (!this.item || !this.item.shop_item_id) {
          this.reset_form();
        }
        else {
          this.form = { ...this.item }
          this.form.is_profile = this.form.is_profile !== null && this.form.is_profile !== undefined ? this.form.is_profile : false;
        }
      }
    },
  },

  methods: {

    // when we have the intention to create the renew batch
    save_data() {
      this.$nextTick(()=>{

        // perform validations
        if (this.form.group_id) {
          this.update_group();
        }
        else {
          this.create_group();
        }

      });
    },

    async create_group() {

      try {

        this.form.company_id = this.currentCompanyId;
        this.form.period_id = this.currentPeriodId;

        const res = await axios.post('/property_group', this.form);

        if (res.status === 201) {

          this.form = res.data;

          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.ATTRIBUTES.CATEGORY_CREATED'));

          this.$emit('created', res.data);
        }
        else if (res.status === 409) {
          // too long
          this.toastr('danger', this.$t('COMMON.OK'), this.$t('PAGES.ATTRIBUTES.TOO_LONG'));
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.ATTRIBUTES.UNABLE_CATEGORY_CREATE'));
        }
      }
      catch (err) {
        console.error('create_group error', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.ATTRIBUTES.UNABLE_CATEGORY_CREATE'));
      }

      this.$refs['save-button'].stop();

    },

    async update_group() {

      try {

        const res = await axios.put(`/property_group/${this.form.group_id}`, this.form);

        if (res.status === 200) {
          this.form = res.data;

          // this.form.mandatory = res.data.is_mandatory === 1;
          // this.form.registration = res.data.is_registration === 1;

          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.ATTRIBUTES.CATEGORY_UPDATED'));

          this.$emit('updated', this.form);
        }
        else if (res.status === 409) {
          // too long
          this.toastr('danger', this.$t('COMMON.OK'), this.$t('PAGES.ATTRIBUTES.TOO_LONG'));
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.ATTRIBUTES.UNABLE_CATEGORY_UPDATE'));
        }

      }
      catch (err) {
        console.error('update_group', err);

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.ATTRIBUTES.UNABLE_CATEGORY_UPDATE'));
      }

      this.$refs['save-button'].stop();

    },

    reset_form() {
      this.form = {
        name: '',
        descr: '',
        is_profile: false
      }
    }
  }
};
</script>
