<template>
  <div id="page-property_groups">
    <h5 class="m-4">{{$t('PAGES.ATTRIBUTES.HEADER')}}</h5>

    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('MEMBER_PROPERTY.CONFIRM_DELETE')"
      ref="deleteGroupConfirm"
      type="success"
      @confirm="on_delete_group_confirm"
    />
    
    <PropertyGroupTable
      :items="groups"
      @create_group_clicked="create_group_clicked"
      @select_group_clicked="select_group_clicked"
      @delete_group_clicked="delete_group_clicked"
    />

    <PropertyGroupModal
      ref="property-group-modal"
      :item="selected_item"

      @created="created_group"
      @updated="updated_group"
    />
    
  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import PropertyGroupModal from './member_property/PropertyGroupModal.vue';
import PropertyGroupTable from './member_property/PropertyGroupTable.vue';


export default {
  name: 'PropertyGroups',
  components: {
    Confirm,
    PropertyGroupModal,
    PropertyGroupTable,
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'sid'])
  },
  mounted() {
    
    this.load_groups();
  },
  data() {
    return {
      page_index: 0,
      selected_item: {},
      groups: [],
      toDelete: null,
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.load_groups();
      }
    }
  },
  methods: {

    answer_selected(property_group, selection) {
      console.log('final selected answer ', property_group, selection)
    },
    
    create_group_clicked() {
      this.selected_item = {};
      this.$refs['property-group-modal'].show();
    },

    delete_group_clicked(group_id) {
      this.toDelete = group_id;
      this.$refs['deleteGroupConfirm'].show();
    },
    on_delete_group_confirm() {
      this.delete_group(this.toDelete);
      this.toDelete = null;
    },

    created_group(group) {
      this.groups.push(group);
    },

    updated_group(group) {
      console.log('group updated', group);
      const i = this.groups.findIndex(item => item.group_id === group.group_id);

      this.groups[i] = group;

      this.groups = [...this.groups];
    },
    async delete_group(group_id) {
      try {
        const res = await axios.delete(`/property_group/${group_id}`)
        
        if (res.status === 204) {
          this.groups = this.groups.filter(item => item.group_id !== group_id);

          this.creating = false;
          this.editing = false;

          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.ATTRIBUTES.GROUP_DELETED'));
        } else if (res.status === 409) {
          this.toastr('success', this.$t('COMMON.ERROR'), this.$t('PAGES.ATTRIBUTES.UNABLE_DELETE_GROUP_CONFLICT'));
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.ATTRIBUTES.UNABLE_DELETE'));
        }
        
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.ATTRIBUTES.UNABLE_DELETE'));
      }
      
    },
    async select_group_clicked(group_id) {

      try {
        const res = await axios.get(`/property_group/${group_id}?company_id=${this.currentCompanyId}`)
        
        if (res.status === 200) {
          this.selected_item = res.data;

          this.selected_item.mandatory = res.data.is_mandatory === 1;
          this.selected_item.registration = res.data.is_registration === 1;

          this.$nextTick(()=>{
            this.$refs['property-group-modal'].show();
          });
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.ATTRIBUTES.UNABLE_GET'));
      }
      
    },
    
    async load_groups() {
      try {
        this.groups = [];
        const res = await axios.get(this.sid === this.currentCompanyId 
          ? `/property_group`
          : `/property_group?company_id=${this.currentCompanyId}&period_id=${this.currentPeriodId}`
        );

        if (res.status === 200) {
          this.groups = res.data;
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.ATTRIBUTES.UNABLE_LIST'));
        }
      }
      catch (err) {
        console.error('load_groups error', err);
        
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.ATTRIBUTES.UNABLE_LIST'));
      }
      
    },

  }
};
</script>
